import * as React from "react"
import { Script } from "gatsby"

export const CommonHead = () => {
  return (
    <>
      <Script id="cookie-banner">{`
    // OneTrust
    (function() {
        var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.charset = "UTF-8";
          s.setAttribute('data-domain-script','d8606fe0-eb46-49e2-9118-e39304fc0f7b');
          s.src = '//cdn.cookielaw.org/scripttemplates/otSDKStub.js';

          document.getElementsByTagName('head')[0].appendChild(s);
    })();

    // Optanon
    function OptanonWrapper() { }
    `}</Script>
      <Script id="zva">{`
    // ZVA (Zoom Virtual Agent)
    (function() {
      var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "//us01ccistatic.zoom.us/us01cci/web-sdk/chat-client.js";
        s.setAttribute("data-apikey", "cApw03CPSvulYZ60hsJZyA");
        s.setAttribute("data-env", "us01")

        document.getElementsByTagName('head')[0].appendChild(s);
  })();
  `}</Script>
    </>
  )
}
