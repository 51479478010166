import * as React from "react"
import { FetchSiteMetadata } from "~components/sitemetadata"

export const SeoHead = (props) => {
  // const { data } = props
  // console.log("SEOHEAD\n%o", props)

  const { siteUrl, title: siteTitle } = FetchSiteMetadata()

  const pageUrl = props.location.pathname
  const fullUrl = siteUrl + pageUrl

  let pageLang = "en"

  if (props?.frontmatter?.lang) {
    // use synthetic frontmatter
    pageLang = props.frontmatter.lang
  } else {
    // pull it out of the context's frontmatter
    pageLang = props.pageContext.frontmatter.lang
  }

  // set default to a known empty string, so better than a "null" or "undefined" string
  let pageTitle = ""

  if (props?.frontmatter?.title) {
    // use synthetic frontmatter first,
    // mostly so pages can pass their statics in,
    // or guides et al can pass their calculated ones in
    pageTitle = props.frontmatter.title
  } else {
    // if no props synth title, check the context for an override,
    // failing that, attempt to use the guide style 'header' frontmatter, should get a partial title, without the suffix
    // if that fails also, maybe just call the page "Fastly Documentation" from siteTitle, so its not blank?
    pageTitle = props.pageContext.frontmatter?.title || props.pageContext.frontmatter?.header || siteTitle
  }

  return (
    <>
      <link rel="canonical" href={fullUrl} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:locale" content={pageLang} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={`${siteUrl}/assets/images/logo-facebook.png`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@fastly" />
      <meta property="article:publisher" content="https://www.facebook.com/FastlyEdgeCloud" />
      <script type="application/ld+json">
        {`{"@type":"WebPage",
      "url":"${fullUrl}",
      "image":"${siteUrl}/assets/images/logo-twitter.png",
      "headline":"${siteTitle}",
      "@context":"http://schema.org"}`}
      </script>
    </>
  )
}
