import React from "react"
import SiteDataUrls from "~data/urls.yml"

const DataURL = (urlKey) => {
  return SiteDataUrls[urlKey] ?? ''
}

export const LinkDataURL = (props) => {
  const { to: urlKey, children: innerText, extFlag = true } = props

  return (
    <a href={DataURL(urlKey)} target="_blank" className={`${extFlag ? "force-external" : ""}`} rel="noopener noreferrer">
      {innerText}
    </a>
  )
}

export default DataURL
