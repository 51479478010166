// import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const FetchSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `)

  return data.site.siteMetadata
}
