import * as React from "react"

import FrontmatterPageContext from "~context/FakePageContext"
import { SeoHead } from "~components/Head/SeoHead"

import LayoutCore from "~templates/layout-core"
import { mainArea, main } from "~vanilla/layout-default-full.css"

import { H1 } from "@fastly/consistently"
import { LinkDataURL } from "~components/data_url"
import { FavHead } from "~components/Head/FavHead"
import { CommonHead } from "~components/Head/CommonHead"

//# note: Legal must review changes to this doc. Consider this doc significant for changelog purposes.

const frontmatter = {
  section: "none",
  section_safe: "none",
  title: "Archives | Fastly Documentation",
  lang: "en"
}

const ArchivesIndexPage = () => {
  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-content">
                <H1>Archives</H1>

                Consistent with our commitment to transparency, these articles provide links to the historical versions of our documentation, terms, and policies.

                <ul>
                  <li><LinkDataURL to="aup-archive">Acceptable Use Policy archive</LinkDataURL></li>
                  <li><LinkDataURL to="cookie-archive">Cookie Policy archive</LinkDataURL></li>
                  <li><LinkDataURL to="dataprocessing-archive">Data Processing Terms archive</LinkDataURL></li>
                  <li><LinkDataURL to="snapshots-archive">Documentation archive</LinkDataURL></li>
                  <li><LinkDataURL to="pp-archive">Privacy Policy archive</LinkDataURL></li>
                  <li><LinkDataURL to="tos-archive">Terms of Service archive</LinkDataURL></li>
                  <li><LinkDataURL to="ccpa-archive">CCPA Compliance Statement archive</LinkDataURL></li>
                </ul>
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default ArchivesIndexPage

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <CommonHead />
    <SeoHead frontmatter={frontmatter} {...props} />
    <FavHead />
  </>
}
